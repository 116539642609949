import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConfigurationSettings } from "../../configuration-settings"
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from '../loading.service';
import { map,filter, catchError, finalize } from 'rxjs/operators';
import {AnalystExperimentDetailsModel} from 'src/app/models/analyst-experiment-details.model';
import { Subject } from 'rxjs/Subject';
import { throwError} from 'rxjs';
@Injectable({
    providedIn: 'root'
  })
export class AnalystExperimentDetailsService {  
  
    httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json' 
        })
    };
    
    // private sampleCountSource = new BehaviorSubject('none');
    // currentMessage = this.sampleCountSource.asObservable();
    constructor(private http: HttpClient, 
                private fb: FormBuilder,
                private loadingService: LoadingService) {

                 } 
                
  // changeMessage(sampleCount: string) {
  //   this.sampleCountSource.next(sampleCount)
  // }

    public getAnalystExperimentDetailsById(groupRequestId: any): Observable<any> {     
      this.loadingService.setMessage('Loading Request Details...');
      let url = ConfigurationSettings.REST_API_URL + "/api/GroupRequest/getAnalystExperimentDetails?groupRequestId=" + groupRequestId;      
      return this.http.get(url)
                            .pipe(
                              map(result => {
                                return result;
                              }), 
                              catchError((error: HttpErrorResponse) => {
                                console.log(error);
                                return throwError(error);
                              }),
                              finalize(() => {
                                this.loadingService.clearMessage();
                              })
                            );
    }

      public updateAnalystExperimentStatusInfo(groupRequestId, experimentStatus, isActive): Observable<any> {        
        this.loadingService.setMessage('Updating Request Status...');
        let url = ConfigurationSettings.REST_API_URL + "/api/GroupRequest/updateAnalystExperimentStatus";    
        let inputParams = { params: { "groupRequestId": groupRequestId, "experimentStatus":experimentStatus ,"isActive" : isActive } }
        return this.http.put(url, this.httpOptions, inputParams)
        .pipe(
          map(result => {
            return result;
          }), 
          catchError((error: HttpErrorResponse) => {
            console.log(error);
            return throwError(error);
          }),
          finalize(() => {
            this.loadingService.clearMessage();
          })
        );
      }

      
      public UpdateSampleDayStatusInfo(groupRequestId, methodId, sampleDay, methodStatus): Observable<any> {        
        this.loadingService.setMessage('Updating Request Status...');
        let url = ConfigurationSettings.REST_API_URL + "/api/GroupRequest/updateSampleDayStatus";    
        let inputParams = { params: { "groupRequestId": groupRequestId, "methodId":methodId, "sampleDay":sampleDay ,"methodStatus" : methodStatus } }
        return this.http.put(url, this.httpOptions, inputParams)
        .pipe(
          map(result => {
            return result;
          }), 
          catchError((error: HttpErrorResponse) => {
            console.log(error);
            return throwError(error);
          }),
          finalize(() => {
            this.loadingService.clearMessage();
          })
        );
      }

      public UpdateAnalystInfo(groupRequestId, methodId, defaultAnalystId): Observable<any> {        
        this.loadingService.setMessage('Updating Request Status...');
        let url = ConfigurationSettings.REST_API_URL + "/api/GroupRequest/updateAnalyst";    
        let inputParams = { params: {"groupRequestId": groupRequestId, "methodId":methodId,"analystId" : defaultAnalystId } }
        return this.http.put(url, this.httpOptions, inputParams)
        .pipe(
          map(result => {
            return result;
          }), 
          catchError((error: HttpErrorResponse) => {
            console.log(error);
            return throwError(error);
          }),
          finalize(() => {
            this.loadingService.clearMessage();
          })
        );
      }

      public UpdateInstrumentInfo(groupRequestId, methodId, defaultInstrumentId): Observable<any> {        
        this.loadingService.setMessage('Updating Request Status...');
        let url = ConfigurationSettings.REST_API_URL + "/api/GroupRequest/updateInstrument";    
        let inputParams = { params: { "groupRequestId": groupRequestId, "methodId":methodId ,"instrumentId" : defaultInstrumentId } }
        return this.http.put(url, this.httpOptions, inputParams)
        .pipe(
          map(result => {
            return result;
          }), 
          catchError((error: HttpErrorResponse) => {
            console.log(error);
            return throwError(error);
          }),
          finalize(() => {
            this.loadingService.clearMessage();
          })
        );
      }

      public UpdateExperimentNoteInfo(groupRequestId, methodId, experimentNote): Observable<any> {        
        // this.loadingService.setMessage('Updating Request Status...');
        let url = ConfigurationSettings.REST_API_URL + "/api/GroupRequest/updateExperimentNote";    
        let inputParams = { params: { "groupRequestId": groupRequestId, "methodId":methodId ,"experimentNote" : experimentNote } }
        return this.http.put(url, this.httpOptions, inputParams)
        .pipe(
          map(result => {
            return result;
          }), 
          catchError((error: HttpErrorResponse) => {
            console.log(error);
            return throwError(error);
          }),
          finalize(() => {
            this.loadingService.clearMessage();
          })
        );
      }
      public UpdateVisualObservationsInfo(groupRequestId, methodId, visualObservations): Observable<any> {        
        // this.loadingService.setMessage('Updating Request Status...');
        let url = ConfigurationSettings.REST_API_URL + "/api/GroupRequest/updateVisualObservations";    
        let inputParams = { params: { "groupRequestId": groupRequestId, "methodId":methodId  ,"visualObservations" : visualObservations } }
        return this.http.put(url, this.httpOptions, inputParams)
        .pipe(
          map(result => {
            return result;
          }), 
          catchError((error: HttpErrorResponse) => {
            console.log(error);
            return throwError(error);
          }),
          finalize(() => {
            this.loadingService.clearMessage();
          })
        );
      }
     
      public UpdateSampleDayProcessedInfo(groupRequestId, methodId,sampleDay, isProcessed): Observable<any> {        
        this.loadingService.setMessage('Updating sample day processed status...');
        let url = ConfigurationSettings.REST_API_URL + "/api/GroupRequest/updateSampleDayProcessed";    
        let inputParams = { params: { "groupRequestId": groupRequestId, "methodId":methodId ,"sampleDay":sampleDay,"isprocessed" : isProcessed } }
        return this.http.put(url, this.httpOptions, inputParams)
        .pipe(
          map(result => {
            return result;
          }), 
          catchError((error: HttpErrorResponse) => {
            console.log(error);
            return throwError(error);
          }),
          finalize(() => {
            this.loadingService.clearMessage();
          })
        );
      }

      getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "23%";
        return dialogConfig;

      }

      getTextDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "30%";
        return dialogConfig;

      }
}