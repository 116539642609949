import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ConfigurationSettings } from "../../configuration-settings"
import * as _ from 'lodash';
import { LoadingService } from '../loading.service';
import { catchError, map, finalize} from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
  })
export class ResultFilesService {
  private messageSource;
        httpOptions = {
            headers: new HttpHeaders({ 
                'enctype': 'multipart/form-data' 
            })
        };

    constructor(private http: HttpClient, 
                private loadingService: LoadingService) {

                 }


    public UploadFile(formData: FormData):  Observable<any> {
      this.loadingService.setMessage('Uploading file(s)...');
      this.messageSource = new BehaviorSubject(formData);
      let url = ConfigurationSettings.REST_API_URL + "/api/ResultFiles/UploadAsync";
      return this.http.post(
          url,
          formData, this.httpOptions)
          .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
          );
    }

    public getRogueFiles(): Observable<any> {
      this.loadingService.setMessage('Loading files...');
      let url = ConfigurationSettings.REST_API_URL + "/api/ResultFiles/GetRogueFilesAsync";
      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );      
    }

    public deleteRogueFiles(data):  Observable<any> {
      this.loadingService.setMessage('Deleting file(s)...');
      this.messageSource = new BehaviorSubject(data);
      let url = ConfigurationSettings.REST_API_URL + "/api/ResultFiles/DeleteRogueFilesAsync";
      return this.http.post(
          url,
          data, this.httpOptions)
          .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
          );
    }

    public processRogueFiles(data):  Observable<any> {
      this.loadingService.setMessage('Processing file(s)...');
      this.messageSource = new BehaviorSubject(data);
      let url = ConfigurationSettings.REST_API_URL + "/api/ResultFiles/ProcessRogueFilesAsync";
      return this.http.post(
          url,
          data, this.httpOptions)
          .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
          );
    }

    getDialogConfig() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "35%";
      return dialogConfig;

    }
               
}