import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationSettings } from "../../configuration-settings"
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { ExperimentTypeModel } from 'src/app/models/experiment-type.model';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from '../loading.service';

@Injectable({
    providedIn: 'root'
  })
export class ExperimentTypeService {
  private messageSource;
  // currentMessage = this.messageSource.asObservable();
  
    httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json' 
        })
    };

    constructor(private http: HttpClient, private loadingService: LoadingService) { }

    form: FormGroup = new FormGroup({
        ExperimentTypeId: new FormControl(null),
        ExperimentTypeName: new FormControl('', Validators.required),
        Description: new FormControl(''), 
        IsActive: new FormControl(true)
      });

      initializeFormGroup() {
        this.form.setValue({
          ExperimentTypeId: null,
          ExperimentTypeName: '',
          Description: '',
          IsActive: 1
        });
      }

    public add(model: ExperimentTypeModel):  Observable<any> {
      this.loadingService.setMessage('Adding Experiment Type...');
      this.messageSource = new BehaviorSubject(model);
      let url = ConfigurationSettings.REST_API_URL + "/api/ExperimentType/insert";

      let body = JSON.stringify(model);

      return this.http.post(
          url,
          body, this.httpOptions)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }

    public get(): Observable<any> {
      this.loadingService.setMessage('Loading Experiment Types...');
      let url = ConfigurationSettings.REST_API_URL + "/api/ExperimentType/experimentTypes";

      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public cancelStatusUpdate() {
      this.messageSource = new BehaviorSubject('cancel');
    }

    public getById(Id: number): Observable<any> {
      this.loadingService.setMessage('Loading Experiment Type...');
      let url = ConfigurationSettings.REST_API_URL + "/api/ExperimentType/getExperimentTypeById?experimentTypeId="+ Id;
      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public update(model: ExperimentTypeModel):  Observable<any> {
      this.loadingService.setMessage('Updating Experiment Type...');
      let url = ConfigurationSettings.REST_API_URL + "/api/ExperimentType/update";
      this.messageSource = new BehaviorSubject(model);

      let body = JSON.stringify(model);
      return this.http.put(url, body, this.httpOptions)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }

    public updateStatus(Id, isActive): Observable<any> {
      this.loadingService.setMessage('Updating Experiment Type Status...');
      let url = ConfigurationSettings.REST_API_URL + "/api/ExperimentType/updateStatus";
      let inputParams = { params: { "experimentTypeId": Id, "isActive" : isActive } }

      return this.http.put(url, this.httpOptions, inputParams)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }

    populateForm(popupForm) {
        this.form.setValue(_.omit(popupForm,['CreatedBy','CreatedDate','ModifiedBy','ModifiedDate']));
      }

      refreshGrid() {
        let currentMessage;
        if(this.messageSource !== undefined) {
          currentMessage = this.messageSource.asObservable();
          this.messageSource = undefined;
        }
        return currentMessage;
      }

      getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "20%";
        return dialogConfig;

      }
}