import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationSettings } from "../../configuration-settings"
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { MatDialogConfig } from '@angular/material/dialog';
import { MethodModel } from 'src/app/models/method.model';
import { Observable } from 'rxjs';
import { LoadingService } from '../loading.service';
import { InstrumentModel } from 'src/app/models/instrument.model';
import { AnalystModel } from 'src/app/models/analyst.model';

@Injectable({
    providedIn: 'root'
  })
export class MethodService {
  private messageSource;
  // currentMessage = this.messageSource.asObservable();
  
    httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json' 
        })
    };

    constructor(private http: HttpClient, private loadingService: LoadingService) { }

    form: FormGroup = new FormGroup({
        MethodId: new FormControl(null),
        MethodName: new FormControl('', Validators.required),
        Analysts: new FormControl(AnalystModel[''],Validators.required),
        DefaultAnalyst: new FormControl(AnalystModel[''],Validators.required),
        Instruments: new FormControl(InstrumentModel[''],Validators.required),
        DefaultInstrument: new FormControl(InstrumentModel[''],Validators.required),
        TurnAroundTimeInDays: new FormControl('',Validators.required),
        Description: new FormControl(''), 
        IsActive: new FormControl(true)
      });

      initializeFormGroup() {
        this.form.setValue({
          MethodId: null,
          MethodName: '',
          Analysts: null,
          DefaultAnalyst: null,
          Instruments: null,
          DefaultInstrument: null,
          TurnAroundTimeInDays: '',
          Description: '',
          IsActive: true
        });
      }

    public getMethods(): Observable<any> {
      this.loadingService.setMessage('Loading Methods...');
      let url = ConfigurationSettings.REST_API_URL + "/api/Method/methods"
      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public getActiveMethods(): Observable<any> {
      this.loadingService.setMessage('Loading Methods...');
      let url = ConfigurationSettings.REST_API_URL + "/api/Method/methods?getActiveMethods="+ true;
      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }
    
    public getMethodById(methodId: number): Observable<any> {
      this.loadingService.setMessage('Loading Method...');
      let url = ConfigurationSettings.REST_API_URL + "/api/Method/getMethodById?methodId="+ methodId;
      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public addMethod(model: MethodModel): Observable<any> { 
      this.loadingService.setMessage('Adding Method...');
      this.messageSource = new BehaviorSubject(model);
      let url = ConfigurationSettings.REST_API_URL + "/api/Method/insert";
      let body = JSON.stringify(model);

      return this.http.post(
          url,
          body, this.httpOptions)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
  }

    public updateMethod(model: MethodModel): Observable<any> {
      this.loadingService.setMessage('Updating Method...');
      let url = ConfigurationSettings.REST_API_URL + "/api/Method/update"
      let body = JSON.stringify(model);
      return this.http.put(url, body, this.httpOptions)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }

    public updateStatus(methodId, isActive): Observable<any> {
      this.loadingService.setMessage('Updating Method Status...');
      let url = ConfigurationSettings.REST_API_URL + "/api/Method/updateStatus"
      let inputParams = { params: { "methodId": methodId, "isActive" : isActive } }

      return this.http.put(url, this.httpOptions, inputParams)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }

    populateForm(method) {
        // this.form.setValue(group);
        this.form.setValue(_.omit(method,['CreatedBy','CreatedDate','ModifiedBy','ModifiedDate','DefaultInstrumentId','DefaultAnalystId','show']));
      }

      setFormValue(values) {
        this.form.controls['Analysts'].setValue(['Deann Nelson']);
      }

      public cancelStatusUpdate() {
        this.messageSource = new BehaviorSubject('cancel');
      }

      refreshGrid() {
        let currentMessage;
        if(this.messageSource !== undefined) {
          currentMessage = this.messageSource.asObservable();
          this.messageSource = undefined;
        }
        return currentMessage;
      }

      getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "30%";
        // dialogConfig.height = "80%"
        return dialogConfig;

      }
}